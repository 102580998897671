import axios from 'axios'
import { Dispatch, SetStateAction } from 'react'
import { IndustryCodeEnum } from './IndustryCodeEnum'

export interface handleSubscribeToNewsletterProps {
  setIsSubscribing: Dispatch<SetStateAction<boolean>>
  setDisplaySuccessPrompt: (display: boolean) => Dispatch<SetStateAction<boolean>>
  handleError: (e: Error) => void
  email?: string
  customerFirstName?: string
  customerLastName?: string
  customerIndustry?: string
  isLoggedIn?: boolean
  additionalProperties?: Record<string, boolean>
}

export const handleSubscribeToNewsletter = async (
  setIsSubscribing: Dispatch<SetStateAction<boolean>>,
  setDisplaySuccessPrompt: Dispatch<SetStateAction<boolean>>,
  handleError: (e: Error) => void,
  email?: string | undefined,
  customerFirstName?: string,
  customerLastName?: string,
  customerIndustry?: string,
  isLoggedIn?: boolean,
  additionalProperties?: Record<string, boolean>,
) => {
  setIsSubscribing(true)
  const body = {
    type: 'profile',
    attributes: {
      email,
      first_name: customerFirstName ?? '',
      last_name: customerLastName ?? '',
      properties: {
        ...additionalProperties,
      },
    },
  }

  const result = await axios
    .request({
      method: 'POST',
      url: 'api/subscribeEmailToNewsletter',
      data: body ?? '',
    })
    .catch((e: Error) => {
      handleError(e)
    })
  if (result) {
    const profileId = result.data?.id

    let subscriptionBody = {}
    if (!isLoggedIn) {
      subscriptionBody = {
        type: 'profile-subscription-bulk-create-job',
        attributes: {
          profiles: {
            data: [
              {
                type: 'profile',
                id: profileId,
                attributes: {
                  email,
                  subscriptions: {
                    email: {
                      marketing: {
                        consent: 'SUBSCRIBED',
                      },
                    },
                  },
                },
              },
            ],
          },
          historical_import: false,
        },
        relationships: {
          list: {
            data: {
              type: 'list',
            },
          },
        },
      }
    } else {
      let persona
      switch (customerIndustry) {
        case IndustryCodeEnum.bar_restaurant.toLocaleString():
          persona = process.env.NEXT_PUBLIC_KLAVIYO_BAR_RESTAURANT_ID
          break
        case IndustryCodeEnum.cafe_bakery.toLocaleString():
          persona = process.env.NEXT_PUBLIC_KLAVIYO_CAFE_BAKERY_ID
          break
        case IndustryCodeEnum.designer.toLocaleString():
          persona = process.env.NEXT_PUBLIC_KLAVIYO_DESIGNER_ID
          break
        case IndustryCodeEnum.hotel.toLocaleString():
          persona = process.env.NEXT_PUBLIC_KLAVIYO_HOTEL_ID
          break
        case IndustryCodeEnum.personal.toLocaleString():
          persona = process.env.NEXT_PUBLIC_KLAVIYO_PERSONAL_USE_ID
          break
        case IndustryCodeEnum.retail.toLocaleString():
          persona = process.env.NEXT_PUBLIC_KLAVIYO_RETAIL_ID
          break
        default:
          persona = process.env.NEXT_PUBLIC_KLAVIYO_NEWSLETTER_ID
          break
      }
      subscriptionBody = {
        type: 'profile-subscription-bulk-create-job',
        attributes: {
          profiles: {
            data: [
              {
                type: 'profile',
                id: profileId,
                attributes: {
                  email,
                  subscriptions: {
                    email: {
                      marketing: {
                        consent: 'SUBSCRIBED',
                      },
                    },
                  },
                },
              },
            ],
          },
          historical_import: false,
        },
        relationships: {
          list: {
            data: {
              type: 'list',
              id: persona,
            },
          },
        },
      }
    }
    const subcriptionResult = await axios
      .request({
        method: 'POST',
        url: 'api/addEmailToNewsletterList',
        data: subscriptionBody ?? '',
      })
      .catch((e: Error) => {
        handleError(e)
      })
    if (subcriptionResult) {
      setDisplaySuccessPrompt(true)
    }
  }
  setIsSubscribing(false)
}
