import { ApolloError } from '@graphcommerce/graphql'
import { useCartQuery } from '@graphcommerce/magento-cart'
import { ApolloCustomerErrorAlert, useCustomerQuery } from '@graphcommerce/magento-customer'
import {
  GuestNewsletterToggleDocument,
  GuestNewsletterToggleMutation,
  GuestNewsletterToggleMutationVariables,
} from '@graphcommerce/magento-newsletter/components/GuestNewsletterToggle/GuestNewsletterToggle.gql'
import { GetCartEmailDocument } from '@graphcommerce/magento-newsletter/components/SignupNewsletter/GetCartEmail.gql'
import { Form } from '@graphcommerce/next-ui'
import { Controller, useFormAutoSubmit, useFormGqlMutation } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
  SwitchProps,
  SxProps,
  Theme,
} from '@mui/material'
import { AxiosError } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { CustomerDocument } from '../../graphql/Customer.gql'
import { handleSubscribeToNewsletter } from '../BuilderIO/KlaviyoNewsletterApiCall'
import { HighlightButton } from '../Button/ButtonStyles'
import { AlertPrompt } from '../Prompt/AlertPrompt'
import { ConfirmationPrompt } from '../Prompt/ConfirmationPrompt'
import { ErrorPrompt } from '../Prompt/ErrorPrompt'
import { Spinner } from '../Spinner'

export type GuestNewsletterToggleProps = SwitchProps & { sx?: SxProps<Theme> }

export function GuestNewsletterToggle(props: GuestNewsletterToggleProps) {
  const { sx = [], ...switchProps } = props
  const dashboard = useCustomerQuery(CustomerDocument, {
    fetchPolicy: 'cache-and-network',
  })
  const customer = dashboard.data?.customer
  const email = customer?.email

  const switchElement = useRef()
  const [userInputEmail, setUserInputEmail] = useState<string>()
  const [displayPrompt, setDisplayPrompt] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false)
  const [isSubscribing, setIsSubscribing] = useState<boolean>(false)
  const [customError, setCustomError] = useState<Error>()

  const form = useFormGqlMutation<
    GuestNewsletterToggleMutation,
    GuestNewsletterToggleMutationVariables & { isSubscribed?: boolean }
  >(
    GuestNewsletterToggleDocument,
    {
      defaultValues: {
        email: userInputEmail ?? email ?? '',
        isSubscribed: true,
      },
      mode: 'onChange',
      onComplete: ({ errors, data }) => {
        if (!errors) {
          setDisplayPrompt(true)
        }
      },
    },
    { errorPolicy: 'all' },
  )
  const { handleSubmit, control, formState, error, register } = form
  const submit = handleSubmit(() => {})
  useFormAutoSubmit({ form, submit })

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setDisplayPrompt(true)
    }
  }, [formState.isSubmitSuccessful])

  useEffect(() => {
    if (error) {
      setChecked(false)
    }
  }, [error])

  const handleError = (e: Error) => {
    if ((e as AxiosError)?.response?.status === 409) {
      setCustomError(new Error('You are already subscribed to our newsletter.'))
    } else {
      setCustomError(
        new Error(
          'We encountered an error while processing your subscription. We will get this issue fixed ASAP.',
        ),
      )
    }
    console.log(e)
  }

  return (
    <Form noValidate sx={sx} onSubmit={submit}>
      <Controller
        name='isSubscribed'
        control={control}
        render={({ field: { onChange, value, name: controlName, ref, onBlur } }) => (
          <FormControl error={!!formState.errors.isSubscribed}>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              label=''
              control={<Switch inputRef={switchElement} className='hidden' checked={checked} />}
              checked={value}
              inputRef={ref}
              onBlur={onBlur}
              name={controlName}
              onChange={(e) => onChange((e as React.ChangeEvent<HTMLInputElement>).target.checked)}
              id='footerSignUpSwitch'
            />
            {formState.errors.isSubscribed?.message && (
              <FormHelperText>{formState.errors.isSubscribed?.message}</FormHelperText>
            )}
            <div className='grid grid-cols-[2fr_1fr] gap-x-5'>
              <input
                id='email'
                placeholder={email ?? 'example@email.com'}
                className='h-[47px] rounded px-[18px] outline-none'
                value={userInputEmail}
                {...register('email')}
                onChange={(e) => setUserInputEmail(e?.target.value)}
                disabled={!!error || formState.isSubmitting || formState.isSubmitSuccessful}
              />
              <HighlightButton
                type='button'
                variant='contained'
                className='w-full bg-marigold uppercase'
                onClick={async () => {
                  if (customer) {
                    await handleSubscribeToNewsletter(
                      setIsSubscribing,
                      setDisplayPrompt,
                      handleError,
                      userInputEmail ?? email ?? '',
                      customer?.firstname ?? '',
                      customer?.lastname ?? '',
                      customer?.company_industry ?? '',
                      !!customer,
                      { subscribed_from_footer: true },
                    )
                  } else {
                    setChecked(true)
                    await submit()
                  }
                }}
                loading={formState.isSubmitting || isSubscribing}
                disabled={formState.isSubmitting}
              >
                Sign me up
              </HighlightButton>
            </div>
          </FormControl>
        )}
      />
      <AlertPrompt
        title={i18n._('Newsletter Sign up')}
        message={i18n._('Thank you for signing up for our newsletter!')}
        isDisplayed={displayPrompt}
        setIsDisplayed={setDisplayPrompt}
      />
      <ErrorPrompt error={error || (customError as ApolloError)} />
    </Form>
  )
}
